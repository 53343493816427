import { setHours } from "date-fns";
import {
  DEFAULT_CLOSING_HOUR,
  DEFAULT_OPENING_HOUR,
  DEFAULT_STORE_TYPE,
  STORE_TYPES,
} from "~/server/constants/store";
import { idSchema, imageUrlSchema, phoneSchema } from "./shared";

export const switchStoreInputSchema = z.object({
  _id: idSchema,
});

export const createStoreInputSchema = z.object({
  name: z.string().min(1),
  address: z.string().min(1),
  type: z.enum(STORE_TYPES).default(DEFAULT_STORE_TYPE),
  ownerId: idSchema,
});

export const paymentMethodSchema = z.object({
  id: idSchema,
  feesPercentage: z.coerce.number().nonnegative(),
});

export const coinSchema = z.object({
  id: idSchema,
  quantity: z.coerce.number().nonnegative(),
});

export const workingDaysSchema = z
  .array(
    z.object({
      open: z.coerce.date().nullable(),
      close: z.coerce.date().nullable(),
    }),
  )
  .length(7)
  .default(
    [1, 2, 3, 4, 5, 6, 7].map(() => ({
      open: setHours(new Date(), DEFAULT_OPENING_HOUR),
      close: setHours(new Date(), DEFAULT_CLOSING_HOUR),
    })),
  );

export const positiveTrendThresholdSchema = z.coerce.number().min(0).max(100);
export const negativeTrendThresholdSchema = z.coerce.number().min(-100).max(0);

export const updateStoreInputSchema = createStoreInputSchema
  .omit({ type: true, ownerId: true })
  .partial()
  .merge(
    z.object({
      _id: idSchema,
      imageUrl: imageUrlSchema.optional(),
      phone: phoneSchema.optional(),
      acceptedPaymentMethods: paymentMethodSchema.array().optional(),
      initialCoins: coinSchema.array().optional(),
      autoAcceptAuthorizedEntrepriseOrders: z.boolean().optional(),
      positiveTrendThreshold: positiveTrendThresholdSchema.optional(),
      negativeTrendThreshold: negativeTrendThresholdSchema.optional(),
    }),
  );

export const getCurrentStoreOutputSchema = z.object({
  _id: idSchema,
  name: z.string().min(1),
  imageUrl: imageUrlSchema.optional(),
  currency: z.string().min(1),
  positiveTrendThreshold: z.number().nonnegative(),
  negativeTrendThreshold: z.number().nonpositive(),
});

export const getAllStoresOutputSchema = z.array(
  z.object({
    _id: idSchema,
    name: z.string().min(1),
    imageUrl: imageUrlSchema.optional(),
  }),
);
